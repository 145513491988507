<template>
  <section>
    <b-table
      :data="isEmpty ? [] : data"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
    >
      <b-table-column
        v-for="(header, index) in headers"
        :key="index"
        :field="header"
        :label="header"
        width="40"
        v-slot="props"
      >
        {{ props.row[header] }}
      </b-table-column>
      <b-table-column width="40">
        <b-button type="is-dark" icon-left="cog"> Zarządzaj </b-button>
      </b-table-column>

      <template v-if="data.length == 0">
        <div class="has-text-centered">Brak wyników</div>
      </template>
    </b-table>
  </section>
</template>

<script>
export default {
  props: {
    data: Array,
    headers: Array,
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
};
</script>
