<template>
  <div class="container">
    <div class="is-size-5">Użytkownicy serwisu</div>
    <div class="is-full-width">
      <div class="tile is-12">
        <b-field>
          <b-input placeholder="Wyszukaj..." type="search" icon="magnify">
          </b-input>
        </b-field>
        <b-field>
          <b-select placeholder="Parametr" icon="account">
            <option
              v-for="(header, index) in headers"
              :key="index"
              :value="header"
            >
              {{ header }}
            </option>
          </b-select>
        </b-field>
        <b-field>
          <b-button class="btn is-info">Filtruj</b-button>
        </b-field>
      </div>
      <table-data :data="users" :headers="headers"></table-data>
      <b-field class="add is-12">
        <b-button class="button is-medium is-fullwidth is-success"
          >Dodaj</b-button
        >
      </b-field>
    </div>
  </div>
</template>

<script>
import Table from "./../../../components/common/Table";

export default {
  name: "AdminUsers",
  components: {
    "table-data": Table,
  },
  computed: {
    users: {
      get() {
        return this.$store.state.admin.users;
      },
    },
    headers: () => {
      return ["uid", "email", "username"];
    },
  },
  created() {
    this.$store.dispatch("admin/loadUsers");
  },
};
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  padding-right: 0.5rem;
}

.add {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>